
  import { Vue, Component } from 'vue-property-decorator'
  import CourseFileApi from '../../api/courseFileApi'
  import getApi from '@/api'
  import { MetaInterface } from '@/interface/BaseInterface'

  const courseFileApi: CourseFileApi = getApi('courseFileApi')

  @Component
  export default class ListFiles extends Vue {
    data: any[] = []
    meta: MetaInterface = {
      page: 1,
      perPage: 10,
      total: 100
    }

    async init() {
      try {
        this.data = await courseFileApi.getCourseFile(this.courseId)
      } catch (error) {
        console.log(error)
      }
    }

    created() {
      this.init()
    }
  }
